import { useEffect, useState } from 'react';

import useModalstore from '../../store/modalStore';
import useDatastore from '../../store/dataStore';
import { getReportList, getReportTaskImage } from '../../_api/rest/common';
import Button from '../common/Button';
import { DeviceDataType, GausiumReportItemType } from '../../_types';
import { AiOutlineFileDone } from 'react-icons/ai';
import { toast } from 'react-toastify';

interface PropsType {
  detailData?: DeviceDataType | null;
}

export default function ReportModal({ detailData }: PropsType) {
  const { closeModal } = useModalstore();
  const { robotDetailData } = useDatastore();

  const [reportList, setReportList] = useState<GausiumReportItemType[] | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean | null>(null);
  useEffect(() => {
    onGetReportList();
  }, []);

  const onGetReportList = () => {
    setLoading(true);
    const param = detailData === undefined ? robotDetailData : detailData;
    getReportList(param).then((res) => {
      setReportList(res.robotTaskReports);
      setLoading(false);
    });
  };

  const handleReportButtonClick = async ({
    deviceId,
    taskId,
  }: {
    deviceId: string;
    taskId: string;
  }) => {
    try {
      const { uri } = await getReportTaskImage({ deviceId, taskId });
      window.open(uri, '_blank');
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  return (
    <div className="flex flex-col justify-around h-[30rem]">
      <section className="flex justify-center w-full">
        <div className="flex flex-col w-full mx-2">
          <h3 className="my-3 mx-0 font-bold text-[#868686]">청소 기록</h3>

          <ul
            id="scroll"
            className="h-[21rem] w-full overflow-auto rounded border-[1px] border-[#d1d1d1]"
          >
            {!reportList && (
              <li className="flex justify-center items-center h-full text-[#868686]">
                {loading
                  ? '청소 기록을 불러오는 중입니다.'
                  : '청소 기록이 존재하지 않습니다.'}
              </li>
            )}

            {reportList &&
              reportList.length > 0 &&
              reportList.map((r, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center px-4 py-2 m-2 bg-[#f6f6f6] rounded-md"
                >
                  <div className="flex flex-col items-start flex-[0.4]">
                    <p className="overflow-hidden text-sm whitespace-nowrap text-ellipsis">
                      {r.displayName}
                    </p>
                    <div className="text-xs">
                      {r.startTime.slice(0, 10)} {r.startTime.slice(11, -1)}
                    </div>
                  </div>

                  <div className="flex items-center flex-[0.6] text-xs">
                    <div className="pl-[6px]">
                      청소영역 {r.actualCleaningAreaSquareMeter.toFixed(2)}m²
                    </div>
                    <div className="pl-[6px]">
                      청소시간 {r.durationSeconds}s
                    </div>
                    <button
                      className="flex items-center justify-center w-8 h-8 ml-auto bg-white border-2 border-gray-300 rounded-md shadow-md"
                      onClick={() =>
                        handleReportButtonClick({
                          taskId: r.id,
                          deviceId: r.robotSerialNumber,
                        })
                      }
                    >
                      <AiOutlineFileDone size={18} />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </section>

      <section className="w-full flex justify-center items-center mx-0 my-[10px]">
        <Button
          outlined
          label="닫기"
          type="button"
          size="small"
          onClick={closeModal}
        />
      </section>
    </div>
  );
}
